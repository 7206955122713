import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`"Through suffering, Christ showed us that our own suffering is worthwhile, and
the occasion through which to grow morally by imitating Him." - Roger Scruton`}</p>
    </blockquote>
    <p>{`If God knows all, and He sent His son to suffer everything that you are
suffering... consider that this must mean your suffering is worthwhile and has a
higher purpose that may be beyond your current comprehension.`}</p>
    <p>{`We are called trust. As much as I don’t, I know I’m called to suffer like Him.
In a righteous submission, trust, and obedience.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      